import { useMemo, useState, MouseEvent, ReactNode } from 'react';
import { useLocation } from 'react-router';
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/contexts/auth-provider';
import { Types } from 'src/generated/types';
import { useRouter } from 'src/hooks';
import Paths from 'src/paths';

interface MainMenuSliderItemProps extends Types.MenuSliderItem {
  isFullWidth?: boolean;
  childVisible?: boolean;
  onChildVisible?: () => void;
  className?: string;
  groupClassName?: string;
  onInvisibleMenu?: (value: boolean) => void;
}

interface MainSubMenuContainerProps {
  href?: string;
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent) => void;
}

function MainSubMenuContainer(props: MainSubMenuContainerProps) {
  if (props.href) {
    return (
      <a {...props} onClick={undefined}>
        {props.children}
      </a>
    );
  }
  return <div {...props}>{props.children}</div>;
}

function MainSubMenuSliderItem(props: MainMenuSliderItemProps) {
  const color = props.active ? 'text-white' : 'text-[#6464b1]';
  const background = props.active ? 'bg-primary' : '';

  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.children && props.children.length > 0) {
      props.onChildVisible && props.onChildVisible();
    } else {
      props.onInvisibleMenu && props.onInvisibleMenu(false);
    }
  };

  return (
    <MainSubMenuContainer
      onClick={onClick}
      href={props.path}
      className={`flex w-full flex-row items-center hover:bg-primary ${background} ${color} ${
        props.active || props?.childVisible ? 'drop-shadow-xl shadow-xl' : ''
      } hover:text-white ${props.className}`}>
      <div className="w-[3.15rem] h-[3.15rem] items-center justify-center cursor-pointer">
        <Iconify icon={props?.icon || ''} className={`w-5 h-5 md:w-6 md:h-6`} />
      </div>
      <div className={`flex-1 ${props.isFullWidth ? 'flex' : 'lg:hidden'} ${props.groupClassName}`}>
        <label className={`text-sm md:text-base cursor-pointer`}>{props.title}</label>
      </div>
      {(props.children || []).length > 0 ? (
        <div
          className={`w-[3.15rem] h-[3.15rem] items-center justify-center cursor-pointer ${
            props.isFullWidth ? 'flex' : 'lg:hidden'
          }`}>
          <Iconify
            icon={props.childVisible ? 'ep:arrow-up-bold' : 'ep:arrow-down-bold'}
            className={`w-4 h-5 md:w-5 md:h-5`}
          />
        </div>
      ) : null}
    </MainSubMenuContainer>
  );
}

export default function MainMenuSliderItem(props: MainMenuSliderItemProps) {
  const { pathname } = useLocation();
  const { enableLottery, auth } = useAuthContext();
  const [childVisible, setChildVisible] = useState<boolean>(false);

  const children = useMemo(() => props.children || [props], [props.children]);
  const paths = useMemo(
    () => (children.length > 0 ? children.map(child => child.path || '') : [props.path || '']),
    [children, props.path],
  );
  const parentActive = useMemo(() => paths.includes(pathname), [paths, pathname]);

  const data = useMemo(() => {
    return children.filter(f => f.path !== Paths.routes.game.football || auth?.id == 10 || auth?.id == 82);
  }, [auth, children]);
  return (
    <div
      className={`${props.isFullWidth ? '' : 'group'} w-full min-w-[14rem] ${
        props.isFullWidth ? 'w-full' : 'lg:w-[3.15rem] lg:min-w-[3.15rem] group-hover:lg:w-full'
      }`}>
      <MainSubMenuSliderItem
        {...props}
        active={parentActive}
        childVisible={childVisible}
        onChildVisible={() => setChildVisible(i => !i)}
        className="group-hover:bg-primary group-hover:text-white"
      />
      {data.length > 0 ? (
        <div
          className={`p-1 border-l-4 border-primary ${
            props.isFullWidth ? 'flex' : 'lg:hidden group-hover:lg:w-[14rem]'
          } ${
            !childVisible ? 'hidden' : ''
          } group-hover:fixed group-hover:left-[3.15rem] group-hover:flex group-hover:border-l-0 group-hover:bg-white group-hover:p-0`}>
          {data.map((child, i) => (
            <MainSubMenuSliderItem
              key={i}
              {...child}
              isFullWidth={props.isFullWidth}
              active={child.path === pathname}
              groupClassName="group-hover:lg:flex"
              onInvisibleMenu={props.onInvisibleMenu}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}
