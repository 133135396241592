import { Types } from 'src/generated/types';

const header = '';

const publics = ['/', `${header}/login`, `${header}/maintenance`];

const routes = {
  home: {
    root: `${header}/`,
  },
  auth: {
    login: `${header}/login`,
    logout: `${header}/logout`,
  },
  game: {
    lotto59: `${header}/game/lotto/59`,
    football: `${header}/game/football`,
    lottery: {
      root: `${header}/game/lottery`,
      timeout: `${header}/game/lottery/timeout`,
      result: `${header}/game/lottery/result`,
      report: `${header}/game/lottery/report`,
      ticket: `${header}/game/lottery/ticket`,
      bet: (gameType?: Types.Lottery.GameType) =>
        `${header}/game/lottery/bet${gameType ? '/' + gameType.toLowerCase() : ''}`,
    },
  },
  user: {
    password: `${header}/user/password`,
  },
  report: {
    lotto59: `${header}/report/lotto/59`,
    lottery: `${header}/report/lottery`,
  },
  ticket: {
    lotto59: `${header}/ticket/lotto59`,
    lottery: `${header}/ticket/lottery`,
  },
  transaction: {
    deposit: `${header}/transaction/deposit`,
    withdraw: `${header}/transaction/withdraw`,
  },
};

const menus: Types.MenuSliderItem[] = [
  {
    title: '1. ផ្សងសំណាង',
    icon: 'icon-park-solid:game-ps',
    children: [
      {
        title: '1.1 លេងឆ្នោតឡូតូសេដ្ឋីនាគ',
        icon: 'game-icons:crystal-ball',
        path: routes.game.lotto59,
      },
      {
        title: '1.2 លេងកម្សាន្តបាល់ទាត់',
        icon: 'game-icons:soccer-ball',
        path: routes.game.football,
      },
      // {
      //   title: '1.2 លេងឆ្នោតប្រាំសំណាង',
      //   icon: 'f7:tickets-fill',
      //   path: routes.game.lottery.root,
      // },
    ],
  },
  {
    title: '2. ផ្ទៀងផ្ទាត់សំបុត្រ',
    icon: 'fluent:slide-search-32-filled',
    children: [
      {
        title: '2.1 មើលឆ្នោតឡូតូសេដ្ឋីនាគ',
        icon: 'mdi:paper-search',
        path: routes.ticket.lotto59,
      },
      // {
      //   title: '2.2 មើលឆ្នោតប្រាំសំណាង',
      //   icon: 'icon-park-solid:doc-search',
      //   path: routes.ticket.lottery,
      // },
    ],
  },
  {
    title: '3. ការដកប្រាក់',
    icon: 'vaadin:money-withdraw',
    children: [
      {
        title: '3.1 ប្រវត្តិដកប្រាក់',
        icon: 'vaadin:cash',
        path: routes.transaction.withdraw,
      },
    ],
  },
  {
    title: '4. ការដាក់ប្រាក់',
    icon: 'vaadin:money-deposit',
    children: [
      {
        title: '4.1 ប្រវត្តិដាក់ប្រាក់',
        icon: 'vaadin:cash',
        path: routes.transaction.deposit,
      },
    ],
  },
  {
    title: '5. របាយការណ៍',
    icon: 'fluent-mdl2:mobile-report',
    children: [
      {
        title: '5.1 របាយការណ៍លេង',
        icon: 'mdi:report-finance',
        path: routes.report.lotto59,
      },
    ],
  },
  {
    title: '6. ប្ដូរពាក្យសម្ងាត់',
    icon: 'mingcute:lock-fill',
    path: routes.user.password,
  },
  {
    title: '7. ចាកចេញ',
    icon: 'raphael:power',
    path: routes.auth.logout,
  },
  // {
  //   title: '7. ប្ដូរទម្រង់ចាស់',
  //   icon: 'ic:twotone-phonelink',
  //   path: '/'
  // }
];

const endpoints = {
  poll: '/api/poll',
  print: '/api/print',
  auth: {
    login: '/login',
    logout: '/logout',
    crsf: '/sanctum/csrf-cookie',
  },
  user: {
    detail: '/api/user',
    changePassword: '/api/user/change_password',
  },
  jackpot: {
    search: '/api/jackpot/search',
    winner: '/api/v1/jackpot/winner',
  },
  result: {
    home: '/api/result/history',
    recent: '/api/v1/result/recent',
    history: '/api/v1/result/history',
    playing: '/api/v1/result/playing',
    releasing: '/api/v1/result/releasing',
  },
  report: {
    bet: '/api/bet/report',
  },
  transaction: {
    list: '/api/transaction/list',
  },
  ticket: {
    list: '/api/v1/ticket/list',
    badge: '/api/v1/ticket/badge',
  },
  bet: {
    range: '/api/bet',
    jackpot: '/api/bet/jackpot',
  },
  sport: { login: '/api/sport/login' },
  lottery: {
    result: {
      time: '/api/lottery/result/time',
      timeout: '/api/lottery/result/timeout',
      root: (id: any) => `/api/lottery/result/${id}`,
    },
    ticket: {
      load: '/api/lottery/ticket/load',
      bet: '/api/lottery/ticket/bet',
      deleteTicket: '/api/lottery/ticket/delete',
      deleteTicketItem: '/api/lottery/ticket/delete/item',
      deletePayout: '/api/lottery/ticket/delete/payout/item',
      payout: '/api/lottery/ticket/payout',
      list: '/api/lottery/ticket/list',
      total: '/api/lottery/ticket/total',
      detail: '/api/lottery/ticket/items',
    },
  },
};

const Paths = {
  menus,
  routes,
  publics,
  endpoints,
  redirectLogin: routes.auth.login,
  redirectAfterLogin: routes.game.lotto59,
};

export default Paths;
