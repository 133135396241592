import { useEffect, useMemo } from 'react';
import useMutationSWR, { SWRMutationConfiguration } from 'swr/mutation';
import { axiosMutate, axiosQuery } from './axios-instanct';
import { AxiosError, AxiosResponse } from 'axios';
import { alertError } from 'src/hooks';

type SWRUriToQuery<K> = K & {
  query?: { id: any };
};
export interface SWRErrorResponse {
  errors: any;
  status: number;
  message: string;
}

export interface SWROptions<T = any, K = any> extends SWRMutationConfiguration<T, SWRErrorResponse, string, K, any> {
  listen?: boolean;
  loadFirst?: boolean;
  disabledAlertError?: boolean;
  method?: 'POST' | 'DELETE' | 'PUT';
}

export const useQuerySWR = <T, K>(url: string, params?: K, options?: SWROptions<T, K>) => {
  let args: any = { ...params };
  const {
    data,
    error,
    reset,
    isMutating: isLoading,
    trigger,
  } = useMutationSWR<T, SWRErrorResponse, any, K>(url, axiosQuery, options);

  const refetch = (_params?: K) => {
    args = { ...args, ..._params };
    // @ts-ignore
    trigger(args);
  };

  useEffect(() => {
    if (options && options.loadFirst) {
      refetch(params);
    }
  }, []);

  useEffect(() => {
    if (
      !options?.disabledAlertError &&
      error &&
      error.status &&
      (error.status === 401 || error.status === 411 || error.status === 419)
    ) {
      alertError({
        message: 'មានឧបករណ៍ផ្សេងលុកចូលគណនីនេះ / សុវត្តិភាពបានហួសសពុលភាព។',
        title: 'សូមចូលម្ដងទៀត',
        onClick: () => window.location.reload(),
      });
    }
  }, [error]);

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      reset,
      refetch,
      error,
    }),
    [data, reset, isLoading, refetch, error],
  );
  return memoizedValue;
};

export const useMutateSWR = <T, K = any>(url: string, params?: K, options?: SWROptions<T, K>) => {
  let args: any = { ...params };
  const {
    data,
    error,
    reset,
    isMutating: isLoading,
    trigger,
  } = useMutationSWR<T, SWRErrorResponse, any, SWRUriToQuery<K>>(
    url,
    axiosMutate(options?.method),
    // @ts-ignore
    options,
  );

  const mutate = (_params?: SWRUriToQuery<K>, _options?: SWROptions<T, K>) => {
    args = { ...args, ..._params };
    // @ts-ignore
    return trigger(args, _options);
  };

  useEffect(() => {
    if (options && options.loadFirst) {
      // @ts-ignore
      mutate(params);
    }
  }, []);

  useEffect(() => {
    if (
      !options?.disabledAlertError &&
      error &&
      error.status &&
      (error.status === 401 || error.status === 411 || error.status === 419)
    ) {
      alertError({
        message: 'មានឧបករណ៍ផ្សេងលុកចូលគណនីនេះ / សុវត្តិភាពបានហួសសពុលភាព។',
        title: 'សូមចូលម្ដងទៀត',
        onClick: () => window.location.reload(),
      });
    }
  }, [error]);

  const memoizedValue = useMemo(
    () => ({
      data,
      isLoading,
      reset,
      mutate,
      error,
    }),
    [data, reset, isLoading, mutate, error],
  );

  return memoizedValue;
};

export interface DownloadExportFileOptions {
  fileName?: string;
  extension?: 'csv' | 'xlsx';
  onError?: (err: AxiosError) => void;
  onSuccess?: (res: AxiosResponse) => void;
}
