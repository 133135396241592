import { Helmet } from 'react-helmet-async';

interface PageHelmetProps {
  title: string;
  isDesktop?: boolean;
}

export default function HelmetPage(props: PageHelmetProps) {
  return (
    <Helmet>
      <title>{props.title}</title>
      {props.isDesktop ? null : (
        <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1, maximum-scale=1" />
      )}
    </Helmet>
  );
}
