import { Helmet } from 'react-helmet-async';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import HelmetPage from 'src/components/helmets/helmet-page';
import { MaintenanceView } from 'src/screens/maintenances/views';

// ----------------------------------------------------------------------

export default function MaintenancePage() {
  return (
    <>
      <HelmetPage title="Maintenance" />
      <MaintenanceView />
    </>
  );
}
